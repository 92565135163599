$(document).ready(function() {
    
    $('#save_view').click( function() {
         $(".form-horizontal").prop("target", "_blank"); 
    });    
    
    $( "#reload_template" ).click(function() {                
        $('#inputHtml').val('');
    }); 
    
    $('[data-toggle="tooltip"]').tooltip({
        delay: {show: 300, hide: 100}        
    });
    
    $('[data-toggle="popover"]').popover();
    
    $("[id^=inputParameter]").on('keyup', function() {
        var max = 500,
            len = this.value.length,
            lbl = $('#'+this.id+'badge');
        if(len >= max) {
            lbl.text(' you have reached the limit')
            .addClass("error-txt");
        } else {
            var ch = max - len;
            lbl.text(ch + ' characters left')
            .removeClass('error-txt');
        }
    });
    
    $("#newItemInput,#pickListSelect").on('keyup blur change', function() {
        addItemButton($("#newItemInput").val(),$("#pickListSelect").val());   
    });   
    var ownerSelectChanged = false;
    var adminSelectChanged = false;
    
    $("#pickListSelect").change(function() { 
        var selectedId = $(this).val();
        var selectedEmail = $("#pickListSelect option:selected").attr("data-email");
        //if(selectedEmail !== undefined) $("#pickListSelect option:selected").text(selectedEmail); // show clean email without icons or abbreviated text
        $("#accountName").removeClass("is-valid");
        if (selectedId && selectedId.length > 0) {           
            
            var tableRow = $("#coOwnersTable > tbody > tr").filter(function() {
                return $(this).attr('id') === selectedId || $(this).attr('id') === selectedEmail;
                 
            });
            
            var haveId = tableRow.attr('id') !== undefined;
            enableBtn("#addCoOwner",!haveId,"fa fa-long-arrow-down");   
            enableBtn("#addModerator",!haveId,null);  
             
            
            if(tableRow.attr('id') !== undefined){
                
                var pos = tableRow.position();
                $('.form-list').scrollTop(pos.top);
                
                $("#coOwnersTable").parent().effect( "shake", {distance:4,duration:900}, function() {
                    tableRow.effect( "pulsate", {times:3, duration:800} );                    
                });                
                                       
               // enableBtn("#addCoOwner",false,"fa fa-long-arrow-down");    
               // enableBtn("#addaddModeratorator",false,null);  
            } else {
              //  enableBtn("#addCoOwner",true,"fa fa-long-arrow-down");   
                enableBtn("#addModerator",true,null); 
                $("#addModerator").effect( "pulsate", {times:2, duration:500}, function() {
                    enableBtn("#addObserver",true,null); 
                    $("#addObserver").effect( "pulsate", {times:2, duration:500});                                              
                });   
            }
            
            var isOwner = $("#owner").val() === selectedId;
            enableBtn("#addOwner",!isOwner,"fa fa-long-arrow-up");
            
            if($("#owner").val() === selectedId) {
                $("#accountName").parent().effect( "shake", {distance:5,duration:800} );     
                //enableBtn("#addOwner",false,"fa fa-long-arrow-up");
      
            }//else enableBtn("#addOwner",true,"fa fa-long-arrow-up");
            
           enableBtn("#send-btn-moderator",true,null);
      
        } else {
           enableBtn("#send-btn-moderator",false,null);
       }
    });  
    
    $(".add-item-btn.disabled").parent().hover(function() { 

        var selectedOption = $("#setCurrentAccountId option:selected");
        var selectedAccount = selectedOption.attr("value");
        if (selectedAccount === undefined || selectedAccount === "" || selectedAccount === "moderated") {           
       
                $("#setCurrentAccountId").switchClass("border-secondary","border-danger");
                setTimeout(function() { 
                            $(this, this > 'btn').removeClass('disabled'); 
                        }, 3000);
                $("#setCurrentAccountId").parent().effect( "shake", {distance:2,duration:800}, function() {
                        //$("#setCurrentAccountId option:eq(0)").effect( "pulsate", {times:3, duration:1000} ); 
                        $("#setCurrentAccountId").switchClass("border-danger", "border-secondary");
                }); 
                $(".add-item-btn > .fa").effect( "pulsate", {times:1, duration:400} ); 
                
                        
            } else {
                 $(this, this > 'btn').removeClass('disabled'); 
            }           
    });  
    
    $("#memberSelect").dblclick(function() { 
        var selectedId = $(this).val();

        var link = "/users/edit/"+selectedId;
        window.open(link, '');

    });  
    
     $("#memberSelect").change(function() { 
        var selectedId = $(this).val();

        $("#ownerSelect").switchClass("is-invalid", "border-secondary");
        $("#adminSelect").switchClass("is-invalid", "border-primary");
        
        if ($("#ownerSelect option[value="+selectedId+"]").length > 0) {  
           enableBtn("#addMember,#addCoOwner,#addAdmin",false,null); 
           enableBtn("#replaceOwner",false,'btn-outline-danger'); 
           
            $("#ownerSelect").switchClass("border-secondary","is-invalid");
            
            $("#ownerSelect option[value="+selectedId+"]").prop('selected', 'selected'); 
            $("#ownerSelect").effect( "shake", {distance:4,duration:900}, function() {    
                $("#ownerSelect option[value="+selectedId+"]").prop('selected', ''); 
                $("#ownerSelect option[value="+selectedId+"]").effect( "pulsate", {times:3, duration:800} );                    
            });           
        }         else if ($("#adminSelect option[value="+selectedId+"]").length > 0) {  
           enableBtn("#addMember,#addCoOwner,#addAdmin",false,null); 
           enableBtn("#replaceOwner",false,'btn-outline-danger'); 
           
            $("#adminSelect").switchClass("border-primary","is-invalid");
            
            $("#adminSelect option[value="+selectedId+"]").prop('selected', 'selected'); 
            $("#adminSelect").effect( "shake", {distance:4,duration:900}, function() {    
                $("#adminSelect option[value="+selectedId+"]").prop('selected', ''); 
                $("#adminSelect option[value="+selectedId+"]").effect( "pulsate", {times:3, duration:800} );                    
            });   
        } else {
           enableBtn("#addMember,#addCoOwner,#addAdmin",true,null); 
           enableBtn("#replaceOwner",true,'btn-outline-danger'); 
           $("#ownerSelect,#adminSelect").switchClass("is-invalid", "border-secondary");
       }

    });  
    
    $("#ownerSelect").change(function() {
        // don't allow removing all
        if($("#ownerSelect > option").length > 1) {     // leave at least one  
            enableBtn("#removeMember",true,null);
            $("#adminSelect").val([]);
        }
        enableSaveBtn(false);
        ownerSelectChanged = true;
        adminSelectChanged = true;
    });  
    
    $("#adminSelect").change(function() {
        if($("#adminSelect > option").length > 1) { // leave at least one   
            enableBtn("#removeMember",true,null);
            $("#ownerSelect").val([]);
        }
        enableSaveBtn(false);
        adminSelectChanged = true;
    });  
    
    
    $("#lpnav-0-tab-footer").addClass("active"); // onload set the first footer tab actibe
     
    $(".card-header-tabs .nav-item.nav-link").click(function() {
        
        var footerId = "#"+$(this).attr('id')+"-footer";
        var allFooter = $("[id$=-tab-footer]");
                      
        $(allFooter).removeClass("active");
        $(footerId).addClass("active");
    });  
    
    // select all options in the multiselect for submission, if changed
    $("#adminPanel > .card-footer .btn-primary, #accountPanel > .card-footer .btn-primary").click(function() { 
       if(ownerSelectChanged || adminSelectChanged) {

           $('#ownerInput > option, #ownerSelect > option, #adminSelect > option').prop('selected', 'selected'); 
           $('select[name=newMembers] > option').prop('selected', 'selected'); 
       }
    });  
    
    $("#adminPanel").fadeIn(600);
    
    $("strong.describes").mouseenter(function() {  
          $(".described").effect( "shake", {distance:4,duration:900});
    });
    
     $("#hideBtn").click(function() {  
         
        $(".describes").fadeOut(300, function(){      
            $('.described').removeAttr('hidden'); 
        });        
     });
     
     $("#revealBtn").click(function() {       
         
         $(".described").fadeOut(300, function(){      
            $('.describes').removeAttr('hidden'); 
            $("#revealBtn").hide();
        });  
         
     });
   
    $("#addCoOwner, #addMember").click(function() {     
        var selectedOption = $("#memberSelect option:selected");    
        $("#ownerSelect").append(selectedOption);
        $("#memberSelect").remove(selectedOption);    
        $(this).tooltip("hide");
        enableBtn("#addMember,#addCoOwner,#removeMember",false,null); 
        enableBtn("#replaceOwner",false,'btn-outline-danger'); 
        $("#ownerSelect,#memberSelect").val("");
        ownerSelectChanged = true;
        enableSaveBtn(true);

    }); 

    $("#removeMember").click(function() {         
       var selectedOption = $("#ownerSelect option:selected");   
       
       if(selectedOption.length > 0) {
        $("#memberSelect").append(selectedOption); 
        $("#ownerSelect").remove(selectedOption);   
        $(this).tooltip("hide");
         enableBtn("#addMember,#addCoOwner,#removeMember",false,null); 
         enableBtn("#replaceOwner",false,'btn-outline-danger'); 
         $("#ownerSelect,#memberSelect").val("");
         ownerSelectChanged = true;
        } else {
            selectedOption = $("#adminSelect option:selected");       
            $("#memberSelect").append(selectedOption); 
            $("#adminSelect").remove(selectedOption);   
            $(this).tooltip("hide");
             enableBtn("#addMember,#addCoOwner,#removeMember",false,null); 
             enableBtn("#replaceOwner",false,'btn-outline-danger'); 
             $("#adminSelect,#memberSelect").val("");
             adminSelectChanged = true;
        }
    
        enableSaveBtn(true);
    }); 
    
    $("#replaceOwner").click(function() {         
       var selectedOption = $("#memberSelect option:selected"); 
       //var confirmed = confirm('Are you sure you want to replace the Organisation Owner? The current owner will be made co-owner.');
       //if(!confirmed) return;

       $("#ownerInput").prepend(selectedOption);
       //$("#ownerInput").html(selectedOption.html()); 
       $(this).tooltip("hide");
        enableBtn("#addMember,#addCoOwner,#removeMember",false,null);
        enableBtn("#replaceOwner",false,'btn-outline-danger'); 
        $("#ownerSelect,#memberSelect").val("");
        
        ownerSelectChanged = true;
        
        enableSaveBtn(true);
    }); 
    
    $("#addAdmin").click(function() {         
       var selectedOption = $("#memberSelect option:selected");  

       $("#adminSelect optgroup").after(selectedOption); 
      
       
       $(this).tooltip("hide");
        enableBtn("#addMember,#addCoOwner,#addAdmin,#removeMember",false,null); 
        $("#ownerSelect,#memberSelect").val("");
        
        adminSelectChanged = true;
        enableSaveBtn(true);
    }); 

    $("#recipients").on('keyup change click', function() {
        if ($(this).val() && $(this).val().length > 3 && $(this).val().indexOf('@') > -1) {
            if($(this).val().indexOf(" ") > -1) {
                var val = $(this).val().replace(/ /g,"");
                $(this).val(val);
            }
            enableBtn("#send-btn-participant,#addObservers",true,null);
            $("#addObservers, #send-btn-participant").effect( "pulsate", {times:1, duration:100} );

        } else {
            enableBtn("#send-btn-participant,#addObservers",false,null);
        }
    });  
  
    var jid = $('#moderatorMapSelect').val();
    if (jid !== undefined ) setModeratorLink(jid); // init
    $("#moderatorMapSelect").change(function() {  

        var isUserEmail = ($("#moderatorMapSelect option:selected").attr("data-email-match") === 'true');
        var linkAppend = $(".appLink .followLink");
        if( !isUserEmail) {           
            linkAppend.attr("data-original-title","This is not your email address, to use this link, logout and use the credentials that were emailed to this person.").tooltip('show');
            linkAppend.addClass('text-danger').effect( "pulsate", {times:1, duration:300});             
        } else {
            linkAppend.removeClass('text-danger');
            linkAppend.attr("data-original-title","Open your personal moderator link in a new tab.").tooltip('hide');
        }   
        $('#moderatorLink').focus(); 
    }); 
    
    $(".removeMod").click(function() { 
        var link = $(this).attr('url');
        //var jid = $(this).attr('jid');
        $("#discussion").attr('action',link);
    }); 
    
    $("#inputTemplate").change(function() {
        $('#confirmModal').modal('show');
        $('#confirmModal').find('.modal-title').text("Switching the template of this landingpage.");
        $('#confirmModal').find('.modal-body').text("You chose another template. " +$(this).attr("data-text"));
        
        $('#confirmModal').find('#confirm').click(function() {                
            submitForm($("#formObject"), 'save_edit', '');
        });    
    });   
    
    $('#confirmReloadDefaultModal #confirm').click(function() {             
        submitForm($("#templateForm"), 'reload_template', '');
    });    
     
    $("input[name=assetId]").click(function() { 
        $("#resetAssets").prop('disabled',false);   
    });   
    
    if($("#setCurrentAccountId").val() === "" || $("#setCurrentAccountId").val() === "00000000-0000-0000-0000-000000000000"){
        $("#setCurrentTimeRange_ALL").prop('disabled',true);
    } else {
       $("#setCurrentTimeRange_ALL").prop('disabled',false); 
    }
    
    $("#resetAssets").click(function() {                
        $("input[name=assetId]").prop('checked', false);
        $(this).prop('disabled',true);   
    });
    
    // prevents accidental double submission of forms
    $(".card-footer .btn.btn-primary, #fileUpload").click(function() { 
       var btn = $(this);
       $(btn).hide();
       
       $(this).after('<span id="loading" class="btn btn-secondary"><i class="fa fa-spinner fa-spin fa-fw"></i></span>');      
       if($(btn).hasClass("btn-lg")) $("#loading").addClass("btn-lg"); 
        setTimeout(function() { 
         $("#loading").fadeOut('slow');
         $("#loading").remove();
         $(btn).fadeIn('slow');
         }, 3000);
    });
    
    $(".dl-excel").click(function() { 
       var btn = $(this);
       
       var classNames = $(btn).hasClass('btn-link') ? "btn-link text-success" : "btn-outline-success";
       $(btn).hide();
       $(btn).after('<span class="btn '+classNames+'" id="loading"><i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i> <i class="fa fa-spinner fa-spin fa-fw"></i></span>');      
       
        setTimeout(function() { 
         $("#loading").fadeOut('slow');
         $("#loading").remove();
         $(btn).fadeIn('slow');
         }, 30000);
       
    }); 

    var profileRadios = $("#discussion input:radio[name='profile']");
    var rl = profileRadios.length;
    if(rl > 6){
        var selectedIndex = profileRadios.index(profileRadios.filter(':checked'));
        $(profileRadios).slice(rl-3, rl).parent().hide();        
        $(profileRadios[selectedIndex]).parent().show();
    }
    $("#revealProfiles").click(function() {                
        $(profileRadios).slice(0, rl).parent().show("drop",{direction:'down'});
        //$("#revealProfiles").hide();
        $("#revealProfiles").effect( "drop",{direction:'down'} );
    }); 
    
    $("#groupSize").change(function() {
        switch($(this).val()) {
            case "S":
              $(profileRadios[3]).prop("checked", true);
              break;
            case "M":
              $(profileRadios[2]).prop("checked", true);
              break;
            case "L":
              $(profileRadios[1]).prop("checked", true);
              break;
          }
    });  
    
    $('#customFile').change(function(){
        var filePath = $(this).val();
        var a = filePath.lastIndexOf("\\");
        var fileName = filePath.substring(a+1,filePath.length);
        $(this).next('.custom-file-label').html(fileName);
        var b = fileName.lastIndexOf(".");
        var simpleFileName = fileName.substring(0,b);
        $('#asset-label').val(simpleFileName);
        
        enableAssetSaveBtn($(this).val());
  
    });
    
    $('#link').on('keyup blur',function(){
       var enable = false;
       if ($(this).val().length > 3) enable = true;
            
       enableAssetSaveBtn(enable);  
    });

    $('#key, #inputName, [id^=inputSc]').on('keyup',function(){ 
       var allowedChars = /^[0-9a-z\-]+$/;
       $(this).val($(this).val().toLowerCase()); 
       if(! $(this).val().match(allowedChars)) {
           $(this).addClass("is-invalid");
           enableSaveBtn(false);
        }
       else { 
           $(this).switchClass('is-invalid','is-valid'); 
           enableSaveBtn(true);
       }
   });
   
//    $("[id^=inputSc]").keypress(function( e ) {
//        if(e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) return true;
//        if(!/[0-9a-z-_]/.test(String.fromCharCode(e.which))) return false;
//    });    
   
    $('#inputEmail, #recipients').on('keyup blur',function(){ 
        if(!$(this).val()) return;
       $(this).val($(this).val().toLowerCase()); 
    });
    
    $('#searchBox,#discussionSearchBox').on('keyup',function(){
        var enable = false;
        if ($(this).val().length > 2) enable = true;
            
        enableSearchBtn(enable);
  
    });
  
    if ($("input[name=mfa]").prop("checked")) {
        $("#phone").prop("required",true);
    } else {
        $("#phone").prop("required",false);
    }
    
    $("input[name=mfa]").change(function(){
        $("#phone").attr("required",$(this).prop("checked"));   
    }); 
    
    showClientBranding();
    
    $("input[name=clientBranding]").change(function(){
        showClientBranding();   
    }); 
   
    $("input[name=autoCss]").change(function(){
        if ($(this).prop("checked")) {       
            var confirmed = confirm('If you set the css back to auto and save, you could lose custom css.');
            if(!confirmed) return;   
        }
        showCssRow();
    }); 
    
    $("#phone").keyup(function(){
        var v = $(this).val();
        if(valid(v)) { 
            $(this).removeClass("is-invalid");
            $(this).addClass("is-valid");
        }
        else {
            $(this).addClass("is-invalid");
        }
    });
    
    $("#phone").change(function(){
        var v = $(this).val();
        if(v !== null && valid(v)) { 
            $("input[name=mfa]").prop("checked",true);
            $("input[name=mfa]").bootstrapToggle('on');      
        }
        else {            
            $("input[name=mfa]").prop("checked",false);
            $("input[name=mfa]").bootstrapToggle('off');
            if(v.length === 0) {
                $(this).removeClass("is-invalid");
                $(this).removeClass("is-valid");
            }            
        }
    });
    
    $("input[type=color]").change(function(){
        var v = $(this).val();
        var s = "#branding\\."+$(this).attr("id");
        $(s).val(v);   
    }); 
    
    $(".mainLink,.shortcut0,.shortcut1,.shortcut2,.appLink").click(function() { 
        $(this).find('.form-control').focus();
        $(this).find('.form-control').select();
        document.execCommand('copy');
        var copyPrepend = $(this).find('.fa.fa-clipboard').parent();
        copyPrepend.attr("data-original-title","copied..").tooltip('show');
        copyPrepend.addClass('text-success').effect( "pulsate", {times:2, duration:600}, function() {    
            copyPrepend.removeClass('text-success');
            copyPrepend.attr("data-original-title","").tooltip('hide');
        });        
    }); 
    
    $(".followLink").click(function() { 
        var link = $(this).parent().parent().find('.form-control').val();
        window.open(link, '_blank');
    });   
    
    $(".rowDeleteBtn").click(function() { 
        $(this).parent().parent().off();
        
    });  
    
   $("#addModerator").mousedown(function() { 
        $("#discussion").prop('action', '/discussions/edit/addModerator'); 
    });  
    
    $("#addObserver").mousedown(function() { 
        $("#discussion").prop('action', '/discussions/edit/addObserver'); 
    }); 
    $("#addObservers").mousedown(function() { 
        $("#discussion").prop('action', '/discussions/edit/addObservers'); 
    }); 
    
//    $("#export-pdf").click()(function() {        
//        $("#html").attr("value",$(".card-body").html());        
//    });  
    
    var uri = window.location.toString();
    var refresh = uri.indexOf("refresh");
    if (refresh> 0) {
        var clean_uri = uri.substring(0, refresh-1);
        window.history.replaceState({}, document.title, clean_uri);
    }
    
    var inactiveModerator = $("#coOwnersTable").find('.text-warning');
    if (inactiveModerator.length > 0) { 
        $("#coOwnersTable").parent().removeClass("border-success").addClass("border-warning");
    }
});

function showClientBranding() {
    if ($("input[name=clientBranding]").prop("checked")) {
        $(".brandingField").show();
        showCssRow();
    } else {
        $(".brandingField").hide();
    }
}

function showCssRow() {
    if ($("input[name=autoCss]").prop("checked")) {         
        $(".brandingField.css").hide();

    } else {
       $("#css").removeAttr('readonly');
       $(".brandingField.css").show("drop",{direction:'up'});

    }  
}   
function enableAssetSaveBtn(value){
    if (value) { 
       $('#fileUpload').switchClass('btn-outline-secondary','btn-success'); 
       $('#fileUpload').removeAttr('disabled'); 
       $('#fa-check').addClass("fa fa-check");

    } else {            
       $('#fileUpload').switchClass('btn-success','btn-outline-secondary'); 
       $('#fileUpload').prop('disabled','disabled'); 
       $('#fa-check').removeClass("fa fa-check");
    }
}

function enableSaveBtn(value){
    if (value) { 
       $('#save ').removeAttr('disabled'); 
       $('#fa-check').addClass("fa fa-check");

    } else {            
       $('#save').prop('disabled','disabled'); 
       $('#fa-check').removeClass("fa fa-check");
    }
}

function enableBtn(button,enabled,icon){
    // button can be multiple comma separated
    if (enabled) {  
           $(button).removeAttr('disabled'); 
           $(button).switchClass('btn-outline-secondary','btn-outline-success'); 
           if(icon !== null) $(button+'> i.fa-fw').addClass(icon);

    } else {            
           $(button).prop('disabled','disabled'); 
           $(button).switchClass('btn-outline-success','btn-outline-secondary'); 
           if(icon !== null) $(button+'> i.fa-fw').removeClass(icon);
    }
}

function enableBtn(button,enabled,style){
    // button can be multiple comma separated
    style = style === null ? 'btn-outline-success' : style;
    if (enabled) {  
           $(button).removeAttr('disabled'); 
           $(button).switchClass('btn-outline-secondary',style); 

    } else {            
           $(button).prop('disabled','disabled'); 
           $(button).switchClass(style,'btn-outline-secondary'); 
    }
}

function enableSearchBtn(value){
    if (value) { 
       $('.searchBtn').switchClass('btn-outline-secondary','btn-success'); 
       $('.searchBtn').switchClass('btn-outline-success','btn-success'); 
       $('.searchBtn').removeAttr('disabled'); 
       //$('#fa-check').addClass("fa fa-check");

    } else {      
       $('.searchBtn').switchClass('btn-success','btn-outline-secondary'); 
       $('.searchBtn').prop('disabled','disabled'); 
       //$('#fa-check').removeClass("fa fa-check");
    }
}
function setModeratorLink(jid){

        if(jid === undefined) return;
        var link = $('#moderatorLink').val();
        var endLink = link.indexOf('user=');
        
        if(jid.includes("#") || jid.includes("@")) {            
            if(endLink > -1) link = link.substring(0,endLink-1);
        }
        else if(endLink > -1) link = link.substring(0,endLink+5) + jid;

        $('#moderatorLink').val(link);    
        $('#moderatorLink').attr('title',link); 
}


function valid(phone) {
    var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    if (regex.test(phone)) {
        return true;
    } 
    return false;
    
}

function submitForm(form, parameter, target){
    
    if (parameter === null || parameter !== "save_view") {
        try {
            $('#save, #save2').prop("disabled","disabled");
        } catch(Exception){}
    }
    if (parameter !== null && parameter !== "") {
       var act = $(form).prop('action'); 
       $(form).prop('action', act +  "?" + parameter);
    }
    $(form).prop("target", (target !== null && target !== "") ? target : "_self");   
    $(form).submit();   
}

function setSortHeaders(baseUrl, currentPage, sort, searchString, active, type){ 
    
    active = active === undefined || active === false ? '' :  "&active=true";
    type = type === undefined ? '' :  "&type="+type;
    searchString = searchString === undefined || searchString.length === 0 ? '' :  "&searchString="+searchString;
    $( "#sortable thead tr th" ).click(function() {                
        var sortCol= $(this).attr("sort-col");        
        if (sortCol) window.location = baseUrl + currentPage + "&sort="+ $(this).attr("sort-col") + searchString + active;
    });

    $( "#sortable thead tr th" ).each(function() {               
        var sortCol= $(this).attr("sort-col");
        if(sortCol){
            if(sort.includes(sortCol)) {                                         
                 if(sort.includes('desc')){
                      $(this).find("i.fa-sort").addClass("fa-sort-desc");
                  } else {
                      $(this).find("i.fa-sort").addClass("fa-sort-asc");  
                  }
             }
            $(this).click(function() {
               if(sort.includes(sortCol) && !sort.includes('desc')) {   
                    window.location = baseUrl + currentPage + "&sort="+sortCol + ',desc' + searchString + active;   
                }
            });
        }
    });
}

function addItemButton(value1,value2){ 
    if (value1 && value2) { 
       $('#addItem').removeAttr('disabled'); 
    } else {
       $('#addItem').prop('disabled','disabled');       
    }   
}

